import React from 'react'
import DesignComponent from '../utilities/DesignComponent'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./Button.scss'

export default function Button({plain = false, ...props}) {
  const classes = `button
    ${props.variant ? ` button--${props.variant}` : ''}
    ${props.state ? ` button--${props.state}` : ''}
    `
  const component =
    <button className={classes}>
      {props.text ? props.text :
        `Button
        ${props.variant ? `${props.variant}` : ''}
        ${props.state ? `${props.state}` : ''}`
      }
    </button>

  const framed =
    <DesignComponent title="Button" styles={styles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed;
}

