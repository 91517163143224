import React from 'react'
import DesignComponent from '../utilities/DesignComponent'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./Article.scss'

export default function Article({plain = false, ...props}) {
  const component =
    <article className={`article`}>
      {props.content}
    </article>

  const framed =
    <DesignComponent title="Article" styles={styles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
