import React from 'react'
import DesignComponent from '../utilities/DesignComponent'
import LinkedInIcon from '../assets/linkedin-icon.svg'
import TwitterIcon from '../assets/twitter-icon.svg'
import InstagramIcon from '../assets/instagram-icon.svg'
import FacebookIcon from '../assets/facebook-icon.svg'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./SocialMenu.scss'

export default function SocialMenu({plain = false, ...props}) {
  const component =
    <nav className={`social-menu ${props.variant ? ` social-menu--${props.variant}` : ''}`}>
      <a className="social-menu__item" href="#linkedin"><img src={LinkedInIcon} alt="Linked In Icon" /></a>
      <a className="social-menu__item" href="#twitter"><img src={TwitterIcon} alt="Twitter Icon" /></a>
      <a className="social-menu__item" href="#instagram"><img src={InstagramIcon} alt="Instagram Icon" /></a>
      <a className="social-menu__item" href="#facebook"><img src={FacebookIcon} alt="Facebook Icon" /></a>
    </nav>

  const framed =
    <DesignComponent title="SocialMenu" styles={styles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
