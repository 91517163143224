import React from 'react'
import HeroImage from '../assets/hero_image.jpg'
import DesignComponent from '../utilities/DesignComponent'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./Hero.scss'
import breadcrumbStyles from '!raw-loader!sass-loader!./Breadcrumbs.scss'

import Breadcrumbs from './Breadcrumbs'

export default function Hero({plain = false, ...props}) {
  let combinedStyles = styles + breadcrumbStyles
  const gridClasses = props.grid ? props.grid : ''
  const component =
    <section className={`hero ${gridClasses}`}>
      <img src={HeroImage} alt="heroImage" className="hero__image" />
      <div className="hero__text-block">
        <Breadcrumbs plain="true" />
        <h1 className="hero__text">Want iedereen heeft recht op goed werk</h1>
      </div>
    </section>

  const framed =
    <DesignComponent title="Hero" styles={combinedStyles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
