import React from 'react'
import {Fragment} from 'react'
import DesignComponent from '../../utilities/DesignComponent'
import Menu from '../Menu'
import SocialMenu from '../SocialMenu'
import Form from '../Form'
import FooterBlock from '../FooterBlock'

/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./Footer.scss'
import footerblockStyles from '!raw-loader!sass-loader!./../FooterBlock.scss'
import menuStyles from '!raw-loader!sass-loader!./../Menu.scss'
import socialmenuStyles from '!raw-loader!sass-loader!./../SocialMenu.scss'
import formStyles from '!raw-loader!sass-loader!./../Form.scss'

export default function Footer({plain = false, ...props}) {
  const combinedStyles = styles + footerblockStyles + menuStyles+ socialmenuStyles + formStyles
  const component =
    <footer className="footer grid grid--6-3-3">
      <FooterBlock variant={'primary'} content={
          <Fragment>
            <h3>Vacatures</h3>
            <h3>Thema's</h3>
            <Menu data={["Kleding", "Palmolie", "Suiker"]} plain="true" variant="vertical" />
            <SocialMenu plain="true" />
          </Fragment>
        } plain={true} />
        <FooterBlock variants={['secondary', 'space-between']} content={
          <Fragment>
            <div>
              <h3>Word vriend donateur</h3>
              <a href="#iets">Schrijf je in</a>
            </div>
            <div>
              <h3>Doe een gift</h3>
              <a href="#ietsanders">Jouw bijdrage werkt</a>
            </div>
          </Fragment>
        } plain="true" />
      <FooterBlock plain="true" variant={'tertiary'} content={
        <Form plain="true" content={<Fragment>
          <h3 className={'form__header'}>Nieuwsbrief</h3>
          <input className={'form__input'} type="text" placeholder="Naam" required />
          <input className={'form__input'} type="email" placeholder="Emailadres" required />
          <input className={'form__checkbox'} type="checkbox" id="checkbox" required /><label htmlFor="checkbox">Ik ontvang graag circa 8x per jaar nieuws, videos en verhalen</label>
          <button className="form__submit" type="submit">Versturen</button>
        </Fragment>} />} className="large" />
    </footer>

  const framed =
    <DesignComponent title="Footer" styles={combinedStyles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
