import React from 'react'
import DesignComponent from '../utilities/DesignComponent'
import ColorBlock from '../utilities/ColorBlock'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./TypoGraphy.scss'
import ColorStyles from '!raw-loader!sass-loader!../utilities/ColorBlock.scss'

export default function TypoGraphy(props) {
  return (
    <DesignComponent title={'TypoGraphy'} styles={styles + ColorStyles} {...props}>
      <section className={'TypoGraphy'.toLowerCase()}>
        <h1>Header 1 (48px bold)</h1>
        <h2>Header 2 (40px normal)</h2>
        <h3>Header 3 (30px bold)</h3>
        <h4>Header 4 (24px bold)</h4>
        <p>This is paragraph text (18px normal): Lorem ipsum dolor sit amet,
           consectetur adipisicing elit. A architecto beatae eligendi facere,
           fuga illo ipsum magnam odit officia optio placeat possimus quam quasi
           ratione rerum suscipit vel veniam! Necessitatibus.</p>
        <p><strong>This is paragraph text STRONG (18px Bold)</strong></p>
        <p className={'text-transform--uppercase'}>
          This is a utility class for uppercase typeface.</p>

        <h3>Colors</h3>
        <ColorBlock name="primary-color" code="#E9AB0D" usage="News, themes" />
        <ColorBlock name="secondary-color" code="#782B90" usage="Donate" />
        <ColorBlock name="tertiary-color" code="#005BA1" usage="Newsletters and links" />
        <ColorBlock name="text-color" code="#000000" usage="Text and icons" />
        <ColorBlock name="text-color--inverted" code="#ffffff" usage="Background and text" />
        <ColorBlock name="text-color--alternative" code="#747171" usage="Menu items" />
        <ColorBlock name="border-color" code="#ada9a9" usage="Border" />
      </section>
    </DesignComponent>
  )
}
