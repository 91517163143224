import React from 'react'

export default function ColorBlock({code, name, usage, ...props}) {
  return (
    <div className="color">
      <span className={`color--${name}`}></span>
      <code>{code.toUpperCase()}</code>
      <span>{name}</span>
      <span>({usage})</span>
    </div>
  )
}
