import React from 'react'
import './Note.scss'

export default function Note(props) {
  return (
    <section {...props}>
      <p>📘 {props.note}</p>
    </section>
  )
}
