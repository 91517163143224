import React from 'react'
import DesignComponent from '../utilities/DesignComponent'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./Form.scss'

export default function Form({plain = false, ...props}) {
  const component =
    <form className={`form form--newsletter-signup`}>
      {props.content}
    </form>

  const framed =
    <DesignComponent title="Form" styles={styles}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
