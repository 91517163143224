import React from 'react'
import DesignComponent from '../utilities/DesignComponent'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./FooterBlock.scss'
import formStyles from '!raw-loader!sass-loader!./Form.scss'
import socialMenuStyles from '!raw-loader!sass-loader!./SocialMenu.scss'
import menuStyles from '!raw-loader!sass-loader!./Menu.scss'

export default function FooterBlock({plain = false, ...props}) {
  const combinedStyles = styles + formStyles + socialMenuStyles + menuStyles
  const variants = props.variants

  const component =
    <section className={`grid__block ${props.variant ? ` grid__block--${props.variant}` : ''} ${variants ? variants.map(el => `grid__block--${el}`).join(' ')
      : ''}`}>
      {props.content}
    </section>

  const framed =
    <DesignComponent title="FooterBlock" styles={combinedStyles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
