import React, {Fragment} from 'react'
import preval from 'preval.macro'
import './App.scss';
import logo from './assets/logo.png'
import InfoBlockImage from './assets/infoblock_image.jpg'
import InfoBlockImageLarge from './assets/infoblock_image-large.jpg'
import InfoBlockVideo from './assets/infoblock_video.jpg'
import IconSuikerriet from './assets/icon_suikerriet.svg'
import IconMijnbouw from './assets/icon_mijnbouw.svg'
import IconEerlijkeKleding from './assets/icon_eerlijke-kleding.svg'
import IconPalmolie from './assets/icon_palmolie.svg'
import ThemeBlockImage from './assets/themeblock_image.jpg'
import ThemeBlockSoloImage from './assets/themeblock-solo_image.jpg'
import ArticleImage from './assets/article_image.jpg'
import Note from './utilities/Note'
import Description from './utilities/Description'
import Button from './decorators/Button'
import TypoGraphy from './components/TypoGraphy'
import Menu from './components/Menu'
import Logo from './components/Logo'
import Search from './components/Search'
import SocialMenu from './components/SocialMenu'
import FooterBlock from './components/FooterBlock'
import Form from './components/Form'
import InfoBlock from './components/InfoBlock'
import Tweets from './components/Tweets'
import Article from './components/Article'
import Hero from './components/Hero'
import Filter from './components/Filter'
import ThemeBlock from './components/ThemeBlock'
import Header from './components/composed/Header'
import Footer from './components/composed/Footer'
import HeaderContainer from './components/composed/HeaderContainer'
import Modal from './decorators/Modal'
import Grid from './decorators/Grid'

function App() {
  const buildDate = preval`module.exports = new Date().toLocaleString()`

  return (
    <div className="app">
      <header className="app-header">
        <img src={logo} alt="" className="app-header-logo" />
        <h1>Design System</h1>
      </header>
      {
        buildDate &&
        <Note note={buildDate && `Last build: ${buildDate}`} className="note note--text-right" />
      }

      <main className="app-body">
        <nav className={'toc'}>
          <h4>Table of Contents</h4>
          <a href="#general">General</a>
          <a href="#decorators">Decorators</a>
          <a href="#components">Components</a>
          <a href="#composed-components">Composed Components</a>
          <a href="#pages">Pages</a>
        </nav>

        <h1 id={'general'}>General</h1>
        <h2>TypoGraphy</h2>
        <Description text="Default typography used on the site." />
        <TypoGraphy className={'large'} viewport="desktop" />
        <h1 id={'decorators'}>Decorators</h1>
        <h2>Buttons</h2>
        <Description text="Button variants and their possible states" />
        <Button />
        <Button variant={'primary'} />
        <Button variant={'secondary'} />
        <Button variant={'tertiary'} />
        <Button state={'large'} variant={'primary'} className={'large'} />
        <Description text="Modal" />
        <Modal content={
          <Fragment>
            <iframe title="Dummy video" width="560" height="315" src="https://www.youtube.com/embed/-abPGzsA14k" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
          </Fragment>
        } className="large" variant="video" />
        <h1>Grid System</h1>
        <Description text="Grid system" />
        <Grid className="large" />
        <h1 id={'components'}>Components</h1>
        <h2>Logo</h2>
        <Description text="The CNV Internatiaal logo" />
        <Logo />
        <h2>Menu</h2>
        <Description text="The main menu" />
        <Menu data={["Home", "Ons werk", "Thema's", "Nieuws", "Contact en pers"]} />
        <Description text="Menu. Variant vertical with primary background and arrow left" />
        <Menu variants={['vertical', 'primary', 'arrow-left']} content={<Fragment>
          <h4 className="menu__header">Inhoud</h4>
        </Fragment>} data={["Item 1 with long text for linebreak", "Item 2", "Item 3", "Item 4", "Item 5", "Item 6", "Item 7", "Item 8"]} className="large" viewport="mobile" />
        <Description text="Menu. Variant vertical with secondary background and arrow right" />
        <Menu variants={['vertical', 'secondary', 'arrow-right']} content={<Fragment>
          <header className="menu__header">Andere mogelijkheden om ons te steunen</header>
        </Fragment>} data={["Geven", "Periodieke gift", "Nalaten", "Vrienden worden", "In plaats van een cadeau", "Laat je sponsoren", "Webshop"]} className="large" viewport="mobile" />
        <Description text="Language menu variant" />
        <Menu variant="language" data={["nl", "en", "fr", "es"]} />
        <h2>Search</h2>
        <Description text="Search bar, used in the header" />
        <Search />
        <h2>Social Menu</h2>
        <Description text="Menu with social icons" />
        <SocialMenu />
        <Description text="Menu with social icons vertical" />
        <SocialMenu variant={'vertical'} className={'large'} />
        <h2>FooterBlock</h2>
        <Description text="Primary block in the footer area" />
        <FooterBlock variant={'primary'} content={
          <Fragment>
            <h3>Vacatures</h3>
            <h3>Thema's</h3>
            <Menu data={["Kleding", "Palmolie", "Suiker"]} plain="true" variant="vertical" />
            <SocialMenu plain="true" />
          </Fragment>
        } className="large" />
        <Description text="Content space between" />
        <FooterBlock variants={['secondary', 'space-between']} content={
          <Fragment>
            <div>
              <h3>Word vriend donateur</h3>
              <a href="#iets">Schrijf je in</a>
            </div>
            <div>
              <h3>Doe een gift</h3>
              <a href="#ietsanders">Jouw bijdrage werkt</a>
            </div>
          </Fragment>
        } className="large" />
        <Description text="Newsletter block in the footer area" />
        <FooterBlock variant={'tertiary'} content={
          <Form plain="true" content={<Fragment>
            <h3 className={'form__header'}>Nieuwsbrief</h3>
            <input className={'form__input'} type="text" placeholder="Naam" required />
            <input className={'form__input'} type="email" placeholder="Emailadres" required />
            <input className={'form__checkbox'} type="checkbox" id="checkbox" required />
            <label htmlFor="checkbox">Ik ontvang graag circa 8x per jaar nieuws, videos en verhalen</label>
            <button className="form__submit" type="submit">Versturen</button>
          </Fragment>} />} className="large" />
        <Description text={'Infoblock links articles and shows information about the articles. This variant shows the default state with a primary background'} />
        <InfoBlock variants={['primary']} content={<Fragment>
          <section>
            <span className="info-block__category">Story</span>
            <h3 className="info-block__header">Integer posuere erat a ante venenatis dapibus posuere velit aliquet.</h3>
            <a href="#foo" className="info-block__read-more">Lees het verhaal van</a>
          </section>
        </Fragment>} className="large" viewport="mobile" />
        <Description text="InfoBlock. Variant with tertiary background and image-top" />
        <InfoBlock variants={['tertiary']} content={<Fragment>
          <img src={InfoBlockImage} alt="infoBlockImage" className="info-block__image" />
          <section>
            <span className="info-block__category">Story</span>
            <h3 className="info-block__header">Integer posuere erat a ante venenatis dapibus posuere velit aliquet.</h3>
            <a href="#foo" className="info-block__read-more">Lees het verhaal van</a>
          </section>
        </Fragment>} className="large" viewport="mobile" />
        <Description text="InfoBlock. Variant with secondary background and image-right" />
        <InfoBlock variants={['secondary']} content={<Fragment>
          <section>
            <span className="info-block__category">Story</span>
            <h3 className="info-block__header">Integer posuere erat a ante.</h3>
            <p>Donec ullamcorper nulla non metus auctor.</p>
            <a href="#foo" className="info-block__read-more">Lees het verhaal van</a>
          </section>
          <img src={InfoBlockImageLarge} alt="infoBlockImage" className="info-block__image" />
        </Fragment>} className="large" viewport="desktop" grid="grid grid--3-9" />
        <Description text="InfoBlock. Variant video." />
        <InfoBlock variants={['primary']} content={<Fragment>
          <img src={InfoBlockVideo} alt="infoBlockImage" className="info-block__image" />
          <section>
            <span className="info-block__category">Video</span>
            <blockquote>
              <p>Donec ullamcorper nulla non metus auctor fringilla. Donec id elit non mi porta gravida at eget
                 metus.</p>
              <cite className="info-block__cite">Jane Doe, vakbondsleider</cite>
            </blockquote>
          </section>
        </Fragment>} className="large" grid="grid grid--5-7" />
        <Description text="Theme Block." />
        <ThemeBlock content={<Fragment>
          <nav className="theme-block__menu">
            <span className="theme-block__category">Thema's</span>
            <p className="theme-block__description">Samenwerken aan beter werk aan het begin van deze internationale
                                                    productieketens</p>
            <div className="theme-block__menu__items">
              <a className="theme-block__menu__item" href="#foo">
                <img src={IconSuikerriet} className="icon" alt="Suikerriet icoon" />
                <h4 className="theme-block__menu__item__title">Suikerriet</h4>
                <p className="theme-block__menu__item__description">Suikerriet. Uitleg over het thema. Lorem ipsum dolor
                                                                    sit amet, consectetur adipiscing elit, sed do
                                                                    eiusmod tempor incididunt ut labore et dolore.</p>
              </a>
              <a className="theme-block__menu__item" href="#foo">
                <img src={IconMijnbouw} className="icon" alt="Mijnbouw icoon" />
                <h4 className="theme-block__menu__item__title">Mijnbouw</h4>
                <p className="theme-block__menu__item__description">Mijnbouw. Uitleg over het thema. Lorem ipsum dolor
                                                                    sit amet, consectetur adipiscing elit, sed do
                                                                    eiusmod tempor incididunt ut labore et dolore.</p>
              </a>
              <a className="theme-block__menu__item" href="#foo">
                <img src={IconEerlijkeKleding} className="icon" alt="Eerlijke kleding icoon" />
                <h4 className="theme-block__menu__item__title">Eerlijke kleding</h4>
                <p className="theme-block__menu__item__description">Eerlijke kleding. Uitleg over het thema. Lorem ipsum
                                                                    dolor sit amet, consectetur adipiscing elit, sed do
                                                                    eiusmod tempor incididunt ut labore et dolore.</p>
              </a>
              <a className="theme-block__menu__item" href="#foo">
                <img src={IconPalmolie} className="icon" alt="Palmolie icoon" />
                <h4 className="theme-block__menu__item__title">Palmolie</h4>
                <p className="theme-block__menu__item__description">Palmolie. Uitleg over het thema. Lorem ipsum dolor
                                                                    sit amet, consectetur adipiscing elit, sed do
                                                                    eiusmod tempor incididunt ut labore et dolore.</p>
              </a>
              <a className="theme-block__menu__item" href="#foo">
                <img src={IconSuikerriet} className="icon" alt="Geweld op het werk icoon" />
                <h4 className="theme-block__menu__item__title">Geweld op het werk</h4>
                <p className="theme-block__menu__item__description">Geweld op het werk. Uitleg over het thema. Lorem
                                                                    ipsum dolor sit amet, consectetur adipiscing elit,
                                                                    sed do eiusmod tempor incididunt ut labore et
                                                                    dolore.</p>
              </a>
              <a className="theme-block__menu__item" href="#foo">
                <img src={IconMijnbouw} className="icon" alt="Corona icoon" />
                <h4 className="theme-block__menu__item__title">Corona</h4>
                <p className="theme-block__menu__item__description">Corona. Uitleg over het thema. Lorem ipsum dolor sit
                                                                    amet, consectetur adipiscing elit, sed do eiusmod
                                                                    tempor incididunt ut labore et dolore.</p>
              </a>
            </div>
          </nav>
          <div className="theme-block__contents">
            <img src={ThemeBlockImage} className="theme-block__contents__image" alt="theme menu" />
          </div>
        </Fragment>} className="large" viewport="desktop" grid="grid grid--6-6" />
        <Description text="Theme Block. Variant solo" />
        <ThemeBlock variant="solo" content={<Fragment>
          <nav className="theme-block__menu">
            <a href="#foo" className="theme-block__menu__item" data-toggle="x">
              <img src={IconSuikerriet} className="theme-block__menu__item__icon" alt="Suikerriet icoon" />
              <h4 className="theme-block__menu__item__title">Suikerriet</h4>
              <p className="theme-block__menu__item__description">Suikerriet. Uitleg over het thema. Lorem ipsum dolor
                                                                  sit amet, consectetur adipiscing elit, sed do eiusmod
                                                                  tempor incididunt ut labore et dolore.</p>
            </a>
          </nav>
          <div className="theme-block__contents" id="x">
            <img src={ThemeBlockSoloImage} className="theme-block__contents__image" alt=""/>
          </div>

        </Fragment>} className="large" grid="grid grid--3-9" />
        <Description text="Article" />
        <Article content={<Fragment>
          <img src={ArticleImage} alt="articleImage" className="article__image" />
          <span className="article__category">Nieuws</span>
          <a className="article__description" href="#foo">Donec ullamcorper nulla non metus auctor fringilla. Donec id
                                                          elit non mi porta gravida at eget metus.</a>
        </Fragment>} className="large" />
        <Description text="Hero" />
        <Hero className="large" />
        <Description text="Tweets" />
        <Tweets className="large" viewport="mobile" />
        <Description text="Filter" />
        <Filter className="large" />
        <h1 id={'composed-components'}>Composed Components</h1>
        <Description text={`Composed components are a larger component made up of smaller components. For example a header.`} />
        <Header className="large" viewport="desktop" />
        <Description text={`Header container containing Hero and info blocks`} />
        <HeaderContainer className="large" viewport="desktop" />
        <Description text={`Footer component`} />
        <Footer className="large" viewport="desktop" />
      </main>
    </div>
  )
}

export default App
