import React, {Fragment, useState} from 'react'
import Framer from 'react-frame-component'
import './DesignComponent.scss'
/* eslint import/no-webpack-loader-syntax: off */
import theme from '!raw-loader!sass-loader!../theme.scss'
import {
  AiOutlineDesktop, AiOutlineMobile, AiOutlineTablet,
} from 'react-icons/all'

export default function DesignComponent({title, styles, children, ...props}) {
  const [data, setData] = useState(props.viewport ? props.viewport : 'tablet')
  const handleViewPort = viewport => {
    setData(viewport)
    iframeReady()
  }
  const identifier = `_${new Date().getTime()}`


  const iframeReady = () => {
    let iframe = document.getElementById(identifier);
    let iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
    if(iframeWin.document.body) {
      iframe.height = (iframeWin.document.documentElement.scrollHeight || iframeWin.document.body.scrollHeight) + 50;
    }
  }

  return (
    <section className="design-component">
      <div className="design-component__viewport-handlers">
        <button title="Mobile" className={`design-component__viewport-handle ${data === 'mobile' ? 'is--active' : ''}`} onClick={() => handleViewPort('mobile')}>
          <AiOutlineMobile /></button>
        <button title="Tablet" className={`design-component__viewport-handle ${data === 'tablet' ? 'is--active' : ''}`} onClick={() => handleViewPort('tablet')}>
          <AiOutlineTablet /></button>
        <button title="Desktop" className={`design-component__viewport-handle ${data === 'desktop' ? 'is--active' : ''}`} onClick={() => handleViewPort('desktop')}>
          <AiOutlineDesktop /></button>
      </div>
      <Framer id={identifier} title={title} head={
        <Fragment>
          <link href="https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700" rel="stylesheet" />
          <style>
            {theme}
            {styles}
          </style>
        </Fragment>
      } {...props} data-viewport={data} contentDidMount={() => iframeReady()}>
        {children}
      </Framer>
    </section>
  )
}
