import React, {Fragment, useState} from 'react'
import DesignComponent from '../utilities/DesignComponent'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./Modal.scss'

export default function Modal({plain = false, ...props}) {
  const [active, setActive] = useState(true);
  const classes = `modal ${active ? 'modal--active' : ''} ${props.variant ? `modal--${props.variant}` : ''}`

  const closeModal = () => {
    setActive(false)
  }

  const component =
    <Fragment>
      <button onClick={() => setActive(!active)}>Toggle modal</button>

      <div className={classes}>
        {active &&
          <Fragment>
            <button className="modal__close" onClick={closeModal}>&times;</button>
            <div className="modal__inner">
              {props.content}
            </div>
          </Fragment>
        }
      </div>
    </Fragment>

  const framed =
    <DesignComponent title="Modal" styles={styles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed;
}

