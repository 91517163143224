import React from 'react'
import DesignComponent from '../utilities/DesignComponent'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./ThemeBlock.scss'

export default function ThemeBlock({plain = false, ...props}) {
  // @Todo rework states to set hover class (toggler)
  const gridClasses = props.grid ? props.grid : ''
  const component =
    <div className={`theme-block ${props.variant ? ` theme-block--${props.variant}` : ''} ${gridClasses}`}>
      {props.content}
    </div>

  const framed =
    <DesignComponent title="ThemeBlock" styles={styles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
