import React from 'react'
import DesignComponent from '../utilities/DesignComponent'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./Menu.scss'

export default function Menu({plain = false, ...props}) {
  const variants = props.variants

  const component =
    <nav className={`menu ${props.variant ? ` menu--${props.variant}` : ''} ${variants ? variants.map(el => `menu--${el}`).join(' ')
    : ''}`}>
      {props.content}
      {props.data.map((el, i) =>
        <a href={`#${el}`} className={`menu__item ${i === 0 ? 'menu__item--active' : ''}`} key={i}>{el}</a>)}
    </nav>

  const framed =
    <DesignComponent title="Menu" styles={styles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
