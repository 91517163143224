import React from 'react'
import DesignComponent from '../utilities/DesignComponent'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./InfoBlock.scss'

export default function InfoBlock({plain = false, ...props}) {
  const variants = props.variants
  const gridClasses = props.grid ? props.grid : ''
  const component =
    <article className={`info-block ${props.variant ? ` info-block--${props.variant}` : ''} ${variants ? variants.map(el => `info-block--${el}`).join(' ')
      : ''} ${gridClasses}`}>
      {props.content}
    </article>

  const framed =
    <DesignComponent title="InfoBlock" styles={styles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
