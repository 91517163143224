import React from 'react'
import DesignComponent from '../utilities/DesignComponent'
import SearchIcon from '../assets/search-icon.svg'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./Search.scss'

export default function Search({plain = false, ...props}) {
  const component =
    <form className={"search"}>
      <input className={"search__input"} type="search" placeholder="Zoeken..." />
      <button className={"search__submit"} type="submit">
        <img src={SearchIcon} alt="search" /></button>
    </form>


  const framed =
    <DesignComponent title="Search" styles={styles}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
