import React from 'react'
import DesignComponent from '../../utilities/DesignComponent'
import Logo from '../Logo'
import Menu from '../Menu'
import Button from '../../decorators/Button'
import Search from '../Search'

/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./Header.scss'
import logoStyles from '!raw-loader!sass-loader!./../Logo.scss'
import menuStyles from '!raw-loader!sass-loader!./../Menu.scss'
import searchStyles from '!raw-loader!sass-loader!./../Search.scss'
import buttonStyles from '!raw-loader!sass-loader!../../decorators/Button.scss'

export default function Header({plain = false, ...props}) {
  const combinedStyles = styles + logoStyles + menuStyles + searchStyles + buttonStyles
  const component =
    <header className="header grid grid--3-6-3">
      <Logo plain={true} />
      <Menu data={["Home", "Ons werk", "Thema's", "Nieuws", "Contact en pers"]} plain={true} />
      <div className="header__actions">
        <Menu data={["Partner login"]} plain={true} />
        <Menu variant="language" data={["nl", "en", "fr", "es"]} plain={true} />
        <Search plain={true} />
        <Button variant="secondary" text="Doneren" plain={true} />
        <Button variant="tertiary" text="Nieuwsbrief" plain={true} />
      </div>
    </header>

  const framed =
    <DesignComponent title="Header" styles={combinedStyles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
