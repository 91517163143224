import React, {Fragment} from 'react'
import DesignComponent from '../../utilities/DesignComponent'
import InfoBlock from '../InfoBlock'
import SocialMenu from '../SocialMenu'
import Hero from '../Hero'
import InfoBlockImage from '../../assets/infoblock_image.jpg'

/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./HeaderContainer.scss'
import infoBlockStyles from '!raw-loader!sass-loader!../InfoBlock.scss'
import socialMenuStyles from '!raw-loader!sass-loader!../SocialMenu.scss'
import heroStyles from '!raw-loader!sass-loader!../Hero.scss'
import breadcrumbStyles from '!raw-loader!sass-loader!../Breadcrumbs.scss'

export default function HeaderContainer({plain = false, ...props}) {
  const combinedStyles = styles + heroStyles + infoBlockStyles + socialMenuStyles + breadcrumbStyles

  const component =
    <section className="header__container grid grid--9-3 grid__rows-2">
      <Hero plain="true" grid="grid__row-span-2" />
      <InfoBlock plain="true" variants={['primary']} content={<Fragment>
        <section>
          <span className="info-block__category">Story</span>
          <h3 className="info-block__header">Integer posuere erat a ante venenatis dapibus posuere velit aliquet.</h3>
          <a href="#foo" className="info-block__read-more">Lees het verhaal van</a>
        </section>
      </Fragment>} />
      <InfoBlock variants={['tertiary']} plain="true" content={<Fragment>
        <img src={InfoBlockImage} alt="infoBlockImage" className="info-block__image" />
        <section>
          <span className="info-block__category">Story</span>
          <h3 className="info-block__header">Integer posuere erat a ante venenatis dapibus posuere velit aliquet.</h3>
          <a href="#foo" className="info-block__read-more">Lees het verhaal van</a>
        </section>
      </Fragment>} />
      <SocialMenu plain="true" variant="vertical" />
    </section>

  const framed =
    <DesignComponent title="HeaderContainer" styles={combinedStyles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
