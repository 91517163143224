import React from 'react'
import DesignComponent from '../utilities/DesignComponent'
import LogoColor from '../assets/logo_color.svg'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./Logo.scss'

export default function Logo({plain = false, ...props}) {

  const component = <img src={LogoColor} className={'Logo'.toLowerCase()} alt="CNV Logo" />

  const framed =
    <DesignComponent title="Logo" styles={styles}>
      {component}
    </DesignComponent>

  return plain ? component : framed;

}
