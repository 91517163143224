import React from 'react'
import './Description.scss'

export default function Description(props) {
  return (
    <section className={'Description'.toLowerCase()}>
      <p>{props.text}</p>
    </section>
  )
}
