import React from 'react'
import DesignComponent from '../utilities/DesignComponent'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./Breadcrumbs.scss'

export default function Breadcrumbs({plain = false, ...props}) {
  const gridClasses = props.grid ? props.grid : ''
  const component =
    <span className={`breadcrumbs ${gridClasses}`}>
      <a href="#foo" className="breadcrumbs__item">Home</a>
      <span>
        <a href="#bar" className="breadcrumbs__item">Thema's</a>
        <span>
          <a href="#tof" className="breadcrumbs__item breadcrumbs__item--active">Corona</a>
        </span>
      </span>
    </span>

  const framed =
    <DesignComponent title="Breadcrumbs" styles={styles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
