import React from 'react'
import DesignComponent from '../utilities/DesignComponent'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./Filter.scss'

export default function Filter({plain = false, ...props}) {
  const component =
    <div className="filter grid grid--4-4-4">
      <h2 className="filter__title">Filteren op:</h2>
      <nav className="filter__items">
        <h4 className="filter__items__title">Thema nieuws</h4>
        <a className="filter__item" href="#foo">Suikerriet (6)</a>
        <a className="filter__item" href="#foo">Mijnbouw (12)</a>
        <a className="filter__item" href="#foo">Eerljke kleding (23)</a>
        <a className="filter__item" href="#foo">Palmolie (7)</a>
      </nav>
      <nav className="filter__items">
        <h4 className="filter__items__title">Thema nieuws</h4>
        <a className="filter__item" href="#foo">Geweld op het werk (6)</a>
        <a className="filter__item" href="#foo">Corona (9)</a>
        <a className="filter__item" href="#foo">Leefbaar loon (14)</a>
        <a className="filter__item" href="#foo">Outsourcing (11)</a>
      </nav>
      <nav className="filter__items">
        <h4 className="filter__items__title">Blogs & vlogs</h4>
        <a className="filter__item" href="#foo">Anneke Westerlaken (15)</a>
        <a className="filter__item" href="#foo">Henk van Beers (9)</a>
      </nav>
      <nav className="filter__items">
        <h4 className="filter__items__title"><a href="#foo">Evenementen</a></h4>
      </nav>
    </div>

  const framed =
    <DesignComponent title="Filter" styles={styles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
