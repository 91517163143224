import React, {Fragment} from 'react'
import DesignComponent from '../utilities/DesignComponent'
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./Grid.scss'

export default function Grid({plain = false, ...props}) {
  const component =
    <Fragment>
      <div className="grid grid--6-6 grid__rows-4 demo">
        <div className="grid grid--3-9">
          <div>Block [3]</div>
          <div>Block [9]</div>
        </div>
        <div className="grid grid--3-9">
          <div>Block [3]</div>
          <div>Block [9]</div>
        </div>
        <div className="grid grid--4-8">
          <div>Block [4]</div>
          <div>Block [8]</div>
        </div>
        <div className="grid grid--5-7">
          <div>Block [5]</div>
          <div>Block [7]</div>
        </div>
        <div className="grid grid--6-6">
          <div>Block [6]</div>
          <div>Block [6]</div>
        </div>
        <div className="grid grid__row-span-2">
          <div>Block [full][Span 2 rows]</div>
        </div>
        <div className="grid grid--6-6">
          <div>Block [6]</div>
          <div>Block [6]</div>
        </div>
      </div>
      <div className="grid demo">
        <div>Block [full]</div>
      </div>
      <div className="grid grid--3-6-3 demo">
        <div>Block [3]</div>
        <div>Block [6]</div>
        <div>Block [3]</div>
      </div>
      <div className="grid grid--6-3-3 demo">
        <div>Block [6]</div>
        <div>Block [3]</div>
        <div>Block [3]</div>
      </div>
    </Fragment>

  const framed =
    <DesignComponent styles={styles} title="Grid" {...props}>{component}</DesignComponent>

  return plain ? component : framed
}
