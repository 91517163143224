import React from 'react'
import DesignComponent from '../utilities/DesignComponent'
import ScriptTag from 'react-script-tag'

/* eslint import/no-webpack-loader-syntax: off */
import styles from '!raw-loader!sass-loader!./Tweets.scss'

export default function Tweets({plain = false, ...props}) {
  const component =
    <section className={`tweets`}>
      <a className="twitter-timeline" data-height="420" data-dnt="true" data-theme="light" href="https://twitter.com/CNV_Internat?ref_src=twsrc%5Etfw">Tweets</a>
      <ScriptTag async={true} type={'text/javascript'} src={'https://platform.twitter.com/widgets.js'} />
    </section>

  const framed =
    <DesignComponent title="Tweets" styles={styles} {...props}>
      {component}
    </DesignComponent>

  return plain ? component : framed
}
